body {
  /* font-family: 'Poppins', sans-serif; */
  color: #6c757d !important;
  background-color: #f5f6f8;
  background-image: url(../images/body-book-bg5.png);
  font-family: "Roboto", sans-serif !important;
  font-family: "Roboto Flex", monospace !important;
  letter-spacing: 0.004rem;
  overflow-x: hidden;
  font-optical-sizing: auto;
  font-weight: 300 !important;
  font-style: normal;
  font-size: 1rem !important;
  font-variation-settings:
    "slnt" 0,
    "wdth" 100,
    "GRAD" 0,
    "XOPQ" 96,
    "XTRA" 468,
    "YOPQ" 79,
    "YTAS" 750,
    "YTDE" -203,
    "YTFI" 738,
    "YTLC" 514,
    "YTUC" 712;
}

.layout_padding {
  padding: 75px 0;
}

.layout_padding2 {
  padding: 45px 0;
}

.layout_padding2-top {
  padding-top: 45px;
}

.layout_padding-top {
  padding-top: 75px;
}

.layout_padding-bottom {
  padding-bottom: 75px;
}

.layout_colored_top {
  background: rgb(230, 236, 255);
  background: linear-gradient(0deg, rgba(230, 236, 255, 0) 0%, rgba(230, 236, 255, 0.7) 100%);
}

.layout_colored {
  background: rgb(157, 180, 254);
  background: linear-gradient(0deg, rgba(157, 180, 254, 0) 0%, rgba(157, 180, 254, 0.40) 50%, rgba(157, 180, 254, 0) 100%);
}

.layout_colored2 {
  background: rgb(233, 138, 152);
  background: linear-gradient(0deg, rgba(233, 138, 152, 0.2) 20%, rgba(179, 182, 235, 0) 100%);
}

.call_to-btn {
  display: inline-block;
  padding: 12px 20px;
  border-radius: 10px;
  font-size: 15px;
  text-transform: uppercase;
  background-color: #fec913;
  border: 1px solid transparent;
  color: #fff;
  transition: all 0.3s ease 0s;
}


.landing_container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.hero_section .call_to-btn {
  padding: 12px 35px;
}

.call_to-btn img {
  width: 18px;
  margin-left: 10px;
}

.btn_on-hover {
  transition: all 0.3s ease 0s;
}

.call_to-btn:hover,
.btn_on-hover:hover {
  color: #fff;
  -webkit-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.7);
  color: #fff;
  transform: translateY(-7px);
}

.colored-red {
  color: #9a0000 !important;
}

.main-heading {
  color: #9a0000;
  font-weight: bold;
  text-align: center;
  margin: 2rem;
}

.curved1 {
  border-bottom-left-radius: 50% 200px;
  border-bottom-right-radius: 50% 200px;
  width: 160%;
  overflow: hidden;
  margin-bottom: -50px;
  position: relative;
  left: -30%;
}

/*header section*/

.header_section {
  padding-top: 10px;
}



nav.navbar.navbar-expand-lg {

  padding: 10px 0;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-link {
  padding: 10px 20px;
  color: #464665b2;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #9a0000;
  cursor: pointer;
  text-decoration: underline;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-item .nav-link.active {
  color: #9a0000;
  text-decoration: underline;
}

.margined-row {
  margin: 8rem 3rem;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}



.top_container {
  height: 80px;
  width: 100%;
  position: absolute;
}



.top_container.sub_pages::before {
  background-size: 400px 200px;
  background-position: top left;
}

.top_container.sub_pages {
  height: 35vh;
}

.top_container.sub_page {
  height: auto;
}

.navbar-brand img {
  width: 25px;
  margin-right: 7px;
}


.custom_nav-container .nav_search-btn {
  /* background-image: url(../images/search-icon.png);
  background-size: 22px;
  background-repeat: no-repeat;
  background-position-y: 7px;
  width: 35px;
  height: 35px; */
  padding: 0;
  border: none;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand span {
  font-size: 20px;
  font-weight: 700;
  color: #fefefe;
}

.custom_nav-container {
  z-index: 99999;
  padding: 15px 0;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.navbar-toggler-icon {
  display: inline !important;
}

.custom_nav-container .navbar-toggler .navbar-toggler-icon {
  /* background-image: url(../images/menu.png);
  background-size: 40px; */
  font-size: larger;
  color: #9a0000;
}



/*end header section*/

/* hero section */

/* .hero_section::before, */
.landing_container::before {
  content: "";
  position: fixed;
  width: 48%;
  height: 100%;
  background: url(../images/hero-bg1.png);
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
  z-index: -1;
}


.hero-container {
  display: flex;
  column-gap: 2rem;
  padding-top: 150px;
  padding-bottom: 75px;
}

.over_banner {
  font-weight: 200;
  color: #9a0000
}

.under_banner {
  line-height: 1.6rem;
  color: #464665;
  padding-top: 3rem;
  display: block;
  text-align: justify;
}

.hero_detail-box {
  color: #fefeff;
  padding: 3rem 2rem;
  border-radius: 20px;
  background-image: url(../images/hero-detail-bg4.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #8a97bf54;
  background-size: contain;

}

.hero_detail-box-img,
.clil-img {
  object-fit: contain;
  width: 100%;
  max-width: 500px;
}

.hero_img-container {
  display: flex;
  justify-content: center;
}


.hero_detail-box h1 {
  text-transform: uppercase;
  font-size: 50px;
  color: #fec913;
  font-weight: bold;
}


.hero_detail-box p {
  margin-top: 20px;
  margin-bottom: 35px;
}


/* end hero section */

/* about section */

.about_img-box {
  width: 65%;
  margin: 40px auto;
}

.about_section_container {
  padding: 1rem;
  position: relative;
  /* box-shadow: 3px 3px 5px 0px rgb(197 181 197);
  border-radius: 20px;
  background-color: #f5f6f8; */
}

/* .about_section_container::before {
  content: '';
  background-image: url('../images/person1.png');
  background-size: 100%;
  border-radius: 50%;
  position: absolute;
  left: 50px;
  width: 300px;
  height: 300px;
  z-index: 1;
} */

.about_img {
  flex: none;
}

.about_text {
  box-shadow: 3px 3px 5px 0px rgb(197 181 197);
  border-radius: 20px;
  background-color: #f5f6f8;
}

/* about section */

/* teacher container */
.teacher_section .card {
  border: none;
  background-color: transparent;
}

.teacher_section .card-title {
  text-align: center;
  color: #48494a;
}

.client_section .client_text {
  z-index: 10;
}

.client_section .clil_card {
  position: relative;
}

.client_section .clil_card::before {
  content: '';
  background-image: url(../images/clil2.png);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: .1;
}


/* end teacher container */

/* vehicle section */
.vehicle_img-box {
  width: 60%;
  margin: 0 auto;
}

.vehicle_section .carousel-control-prev,
.vehicle_section .carousel-control-next {
  opacity: 1;
}

.vehicle_section .carousel-control-prev-icon,
.vehicle_section .carousel-control-next-icon {
  width: 50px;
  height: 50px;
  background-color: #082465;
  border-radius: 100%;
  background-size: 10px;
  background-position: center;
}

.vehicle_section .carousel-control-prev-icon {
  background-image: url(../images/prev.png);
}

.vehicle_section .carousel-control-next-icon {
  background-image: url(../images/next.png);
}



/* end vehicle section */

/* client section */
.client_section {
  background-image: url(../images/shape-11.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 17%;
}

.client_container {
  width: 80%;
  margin: 0 auto;
  padding: 35px;
  background-color: #fefeff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(163, 158, 163, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(163, 158, 163, 1);
  box-shadow: 0px 0px 5px 0px rgba(163, 158, 163, 1);
  border-radius: 20px;
}

.client_detail-box h4 {
  color: #082465;
}

.client_detail-box span {
  text-transform: uppercase;
  color: #48494a;
}

.client_detail .client_img-box {
  width: 100px;
  margin-right: 15px;
}

.client_detail .client_img-box img {
  width: 100%;
}




/* end client section */


/* contact section */
.contact_section {
  background-image: url(../images/shape-22.png);
  background-repeat: no-repeat;
  background-size: 14%;
  background-position: left center;
}

.contact-form {
  padding: 25px;
  border-radius: 20px;
}

.contact-form input {
  border: none;
  outline: none;
  background-color: #dedee0;
  width: 100%;
  margin: 10px 0;
  padding: 7px 10px;
}

.contact-form .input_message {
  height: 120px;
}

.contact-form input::placeholder {
  color: #fff;
}

.contact-form button {
  border: none;
  outline: none;
  padding: 7px 50px;
  text-transform: uppercase;
  margin-top: 25px;
  background-color: #fec913;
  color: #fff;
}

/* end contact section */

/* admission section */


.admission_section .container-fluid.position-relative {
  height: 500px;
}

.admission_container {
  width: 770px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}


.admission_img-box {
  width: 50%;
  border-radius: 25px 0 0 25px;
  overflow: hidden;
}

.admission_detail {
  width: 50%;
  background-color: #082465;
  padding: 20px;
  color: #fefeff;
  border-radius: 0 25px 25px 0;
}


.admission_img-box img {
  width: 100%;
}

.admission_detail .admission_btn {
  background-color: #fec913;
  padding: 7px 35px;
  color: #fefeff;
  font-size: 15px;
  border-radius: 5px;
}

/* end admission section */

/* call section */
.call_section h2 {
  text-align: center;
  margin: 15px 0;
}

.landing_section .number_heading {
  color: #fec913;
}

.landing_section p {
  text-align: center;
  margin-top: 15px;
}

/* end call section */

/* footer section*/

.footer_section {
  /* background-color: #082465; */
  padding: 20px 0;
  font-family: "Roboto", sans-serif;
  background: rgb(233, 138, 152);
  background: radial-gradient(circle, rgba(233, 138, 152, .7) 0%, rgba(179, 182, 235, .7) 100%);
}

.footer_section p {
  color: #fdfdfe;
  margin: 0;
  text-align: center;
}

.footer_section a {
  color: #fdfdfe;
}

/* end footer section*/




/* ******** About Page ******** */

.card-box {

  padding: 1.5rem;
  margin-bottom: 24px;
  border-radius: .25rem;
}

.about-main-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-moto {
  margin: 2rem 4rem 0 0;
  font-style: italic;
  font-size: .9rem;
}

.about-moto-author {
  margin: 1rem 5rem 0 0;
  font-style: italic;
  text-align: right;
  font-size: .9rem;
}

.avatar-xl {
  height: 6rem;
  width: 6rem;
}

.rounded-circle {
  border-radius: 50% !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #1abc9c;
}

.nav-pills .nav-link {
  border-radius: .25rem;
}

.navtab-bg li>a {
  background-color: #f7f7f7;
  margin: 0 5px;
}

.nav-pills>li>a,
.nav-tabs>li>a {
  color: #6c757d;
  font-weight: 600;
}

.mb-4,
.my-4 {
  margin-bottom: 2.25rem !important;
}

.tab-content {
  padding: 20px 0 0 0;
}

.progress-sm {
  height: 5px;
}

.m-0 {
  margin: 0 !important;
}

.table .thead-light th {
  color: #6c757d;
  background-color: #f1f5f7;
  border-color: #dee2e6;
}

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 4px);
  display: block;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  color: #adb5bd;
}

.text-purple {
  color: #6559cc !important;
}

.border-purple {
  border-color: #6559cc !important;
}

.timeline {
  margin-bottom: 50px;
  position: relative;
}

.timeline:before {
  background-color: #dee2e6;
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  top: 30px;
  width: 2px;
  z-index: 0;
}

.timeline .time-show {
  margin-bottom: 30px;
  margin-top: 30px;
  position: relative;
}

.timeline .timeline-box {
  background: #fff;
  display: block;
  margin: 15px 0;
  position: relative;
  padding: 20px;
}

.timeline .timeline-album {
  margin-top: 12px;
}

.timeline .timeline-album a {
  display: inline-block;
  margin-right: 5px;
}

.timeline .timeline-album img {
  height: 36px;
  width: auto;
  border-radius: 3px;
}

@media (min-width: 768px) {
  .timeline .time-show {
    margin-right: -69px;
    text-align: right;
  }

  .timeline .timeline-box {
    margin-left: 45px;
  }

  .timeline .timeline-icon {
    background: #dee2e6;
    border-radius: 50%;
    display: block;
    height: 20px;
    left: -54px;
    margin-top: -10px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px;
  }

  .timeline .timeline-icon i {
    color: #98a6ad;
    font-size: 13px;
    position: absolute;
    left: 4px;
  }

  .timeline .timeline-desk {
    display: table-cell;
    vertical-align: top;
    width: 50%;
  }

  .timeline-item {
    display: table-row;
  }

  .timeline-item:before {
    content: "";
    display: block;
    width: 50%;
  }

  .timeline-item .timeline-desk .arrow {
    border-bottom: 12px solid transparent;
    border-right: 12px solid #fff !important;
    border-top: 12px solid transparent;
    display: block;
    height: 0;
    left: -12px;
    margin-top: -12px;
    position: absolute;
    top: 50%;
    width: 0;
  }

  .timeline-item.timeline-item-left:after {
    content: "";
    display: block;
    width: 50%;
  }

  .timeline-item.timeline-item-left .timeline-desk .arrow-alt {
    border-bottom: 12px solid transparent;
    border-left: 12px solid #fff !important;
    border-top: 12px solid transparent;
    display: block;
    height: 0;
    left: auto;
    margin-top: -12px;
    position: absolute;
    right: -12px;
    top: 50%;
    width: 0;
  }

  .timeline-item.timeline-item-left .timeline-desk .album {
    float: right;
    margin-top: 20px;
  }

  .timeline-item.timeline-item-left .timeline-desk .album a {
    float: right;
    margin-left: 5px;
  }

  .timeline-item.timeline-item-left .timeline-icon {
    left: auto;
    right: -56px;
  }

  .timeline-item.timeline-item-left:before {
    display: none;
  }

  .timeline-item.timeline-item-left .timeline-box {
    margin-right: 45px;
    margin-left: 0;
    text-align: right;
  }
}

@media (max-width: 767.98px) {
  .timeline .time-show {
    text-align: center;
    position: relative;
  }

  .timeline .timeline-icon {
    display: none;
  }
}

.timeline-sm {
  padding-left: 110px;
}

.timeline-sm .timeline-sm-item {
  position: relative;
  padding-bottom: 20px;
  padding-left: 40px;
  border-left: 2px solid #dee2e6;
}

.timeline-sm .timeline-sm-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: -7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #f5f6f8;
  border: 2px solid #9a000075;
}

.timeline-sm .timeline-sm-item .timeline-sm-date {
  position: absolute;
  left: -104px;
}

@media (max-width: 420px) {
  .timeline-sm {
    padding-left: 0;
  }

  .timeline-sm .timeline-sm-date {
    position: relative !important;
    display: block;
    left: 0 !important;
    margin-bottom: 10px;
  }
}


/* ******** END About Page ******** */


/* ******** Contact Page ******** */

.bg-primary {
  background: #2553b8 !important;
}

.ftco-section {
  padding: 7em 0;
}

.ftco-no-pt {
  padding-top: 0;
}

.ftco-no-pb {
  padding-bottom: 0;
}

.heading-section {
  font-size: 28px;
  color: #000;
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.form-control {
  height: 44px;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  border-radius: 2px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #e3e3e3;
  ;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.3) !important;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.3) !important;
}

.form-control:-ms-input-placeholder {
  /* IE 0+ */
  color: rgba(0, 0, 0, 0.3) !important;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.3) !important;
}

.form-control:focus,
.form-control:active {
  border-color: #2553b8 !important;
}

textarea.form-control {
  height: inherit !important;
}

.wrapper {
  width: 100%;
}

/* .contact-wrap {
  background: #e8edf0;
} */

.dbox {
  width: 100%;
  margin-bottom: 25px;
  padding: 0 20px;
}

@media (min-width: 768px) {
  .dbox {
    margin-bottom: 0;
    padding: 0;
  }
}

.dbox p {
  margin-bottom: 0;
}

.dbox p span {
  font-weight: 500;
  color: #000;
}

.dbox p a {
  color: #9a0000;
}

.dbox .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #9a00004d;
  margin: 0 auto;
  margin-bottom: 20px;
}

.dbox .icon span,
.dbox .icon svg {
  font-size: 20px;
  color: #9a0000;
}

.dbox .text {
  width: 100%;
}

.btn {
  padding: 12px 16px;
  cursor: pointer;
  border-width: 1px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  position: relative;
  /* margin-bottom: 20px; */
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.btn:hover,
.btn:active,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.22) !important;
  -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.22) !important;
  box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.22) !important;
}


.btn.btn-primary {
  background: #9a0000 !important;
  border-color: #9a0000 !important;
  color: #fff;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus {
  border-color: #c10000 !important;
  background: #c10000 !important;
}

.contactForm .label {
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.contactForm .form-control {
  border: none;
}

#map {
  width: 100%;
}

@media (max-width: 767.98px) {
  #map {
    height: 300px;
  }
}

#contactForm .error {
  color: red;
  font-size: 12px;
}

#contactForm .form-control {
  font-size: 16px;
}

#message {
  resize: vertical;
}

#form-message-warning,
#form-message-success {
  display: none;
}

#form-message-warning {
  color: red;
}

#form-message-success {
  color: #28a745;
  font-size: 18px;
  font-weight: 500;
}

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-size: 16px;
  font-weight: 500;
  color: #2553b8;
}

.google-map {
  border-radius: 10px;
  border: 1px solid #ccc;
}

/* ******** END Contact Page ******** */

/* *********** QA Page ******** */


.qa-page .card {
  row-gap: .5rem;
  margin-bottom: 3rem;
  background-color: transparent;
  border: none;
  break-inside: avoid-column;
}

.qa-page .card .card-header {
  border-radius: 5px 5px 40% 5px;
  min-height: 80px;
  display: flex;
  align-items: center;
}

.qa-page .card .card-body {
  border-radius: 5px 5% 5px 5px;
  font-size: .9rem;
}

/* ******** END QA Page ******** */


.row-gapped {
  row-gap: 3rem;
}