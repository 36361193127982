.blog_slider {
    width: 95%;
    position: relative;
    max-width: 800px;
    margin: auto;
    background: #f5f6f8;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 25px;
    border-radius: 25px;
    height: 360px;
    display: flex;


    @media screen and (max-width: 992px) {
        max-width: 680px;
        height: 400px;
    }

    @media screen and (max-width: 768px) {
        min-height: 500px;
        height: auto;
        margin: 180px auto;
    }


    @media screen and (max-height: 500px) and (min-width: 992px) {
        height: 350px;
    }

    &__item {
        display: flex;
        align-items: center;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }


    }

    &__img {
        // width: 40%;
        width: 300px;
        flex-shrink: 0;
        height: 300px;
        // background-image: url('/images/person1.png');
        // background-size: 100%;
        box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2);
        border-radius: 20px;
        transform: translateX(-80px);

        overflow: hidden;

        // &:after {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
        //     border-radius: 20px;
        //     opacity: 0.8;
        // }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 20px;
            transition: all .3s;
        }


        @media screen and (max-width: 992px) {
            // width: 45%;
        }

        @media screen and (max-width: 768px) {
            transform: translateY(-50%);
            width: 70%;
        }

        @media screen and (max-width: 576px) {
            width: 95%;
        }

        @media screen and (max-height: 500px) and (min-width: 992px) {
            height: 270px;
        }
    }

    &__content {
        // width: 60%;
        padding-right: 25px;

        @media screen and (max-width: 992px) {
            // width: 55%;
        }

        @media screen and (max-width: 768px) {
            margin-top: -80px;
            text-align: center;
            padding: 0 30px;
        }

        @media screen and (max-width: 576px) {
            padding: 0
        }

        >* {
            opacity: 1;
            transform: translateY(25px);
        }

    }

    &__code {
        color: #7b7992;
        margin-bottom: 15px;
        display: block;
        font-weight: 500;
    }

    &__title {
        font-size: 24px;
        font-weight: 700;
        color: #0d0925;
        margin-bottom: 20px;
    }

    &__text {
        color: #4e4a67;
        margin-bottom: 30px;
        line-height: 1.5em;
    }

    &__button {
        display: inline-flex;
        //background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
        background-color: #9a0000;
        padding: 15px 20px;
        border: 1px solid #9a0000;
        border-radius: 20px;
        color: #fff;
        //box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
        text-decoration: none;
        font-weight: 500;
        justify-content: center;
        text-align: center;
        letter-spacing: .03rem;
        align-items: center;
        gap: 1rem;

        &:hover {
            background-color: transparent;
            color: #9a0000;
        }

        @media screen and (max-width: 576px) {
            width: 100%;
        }

    }

    .swiper_container_horizontal>.swiper_pagination_bullets,
    .swiper_pagination_custom,
    .swiper_pagination_fraction {
        bottom: 10px;
        left: 0;
        width: 100%;

    }



}