.fadeInAndOut {
    -webkit-animation: fadeinout 8s linear forwards;
    animation: fadeinout 8s linear forwards;
    font-size: .9rem;

    &_success {
        color: green;
    }

    &_fail {
        color: red;
    }
}

@-webkit-keyframes fadeinout {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes fadeinout {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}