@media (max-width: 1120px) {
    .hero_detail-box {
        width: 100%;
        margin-bottom: 45px;
    }

    .hero_img-container {
        width: 100%;
        margin: 0 auto;
    }

    .hero_section::before {
        width: 100%;

    }
}

@media (max-width: 992px) {


    .top_container {
        height: auto;
    }

    .top_container.sub_pages {
        height: auto;
    }

    .top_container.sub_pages::before {
        background-size: cover;
    }

    .client_box-container {

        width: 90%;

    }

    .service_detail-container {
        width: 90%;
    }

    .hero-container {
        flex-direction: column;
    }

    .hero_section {
        height: unset;
    }

    .hero_detail-box {
        width: 100%;
        margin-bottom: 45px;
    }

    .hero_img-container {
        width: 100%;
        margin: 0 auto;
    }

    .hero_section::before {
        width: 100%;

    }


    .about_container {
        flex-wrap: wrap;
    }


    .navbar-collapse-inner {
        width: 100%;
        margin: auto;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .custom_nav-container.navbar-expand-lg .navbar-nav .nav-item.active .nav-link,
    .custom_nav-container.navbar-expand-lg .navbar-nav .nav-link {

        color: #fff;
        padding: 13px 0;
    }

    .custom_nav-container .nav_search-btn {
        background-image: url(../images/search-icon-mobile.png);
        background-position: center;
    }
}

@media (max-width: 820px) {
    .admission_container {
        width: 450px;
        flex-direction: column;
    }

    .admission_img-box,
    .admission_detail {
        width: 100%;
    }

    .admission_img-box {
        border-radius: 25px 25px 0 0
    }

    .admission_detail {
        border-radius: 0 0 25px 25px;
    }
}

@media (max-width: 768px) {

    .full-width-container {
        max-width: 100%;
    }

    .hero_detail-box {
        text-align: center;
    }

    .navbar {
        justify-content: center;
    }

}

@media (max-width: 576px) {
    .vehicle_img-box {
        width: 80%;
    }

    .carousel-control-prev,
    .carousel-control-next {
        top: 150%;
    }

    .carousel-control-prev {
        left: 30%;
    }

    .carousel-control-next {
        right: 30%;
    }

    .vehicle_section .carousel-control-prev-icon,
    .vehicle_section .carousel-control-next-icon {
        width: 35px;
        height: 35px;
        background-size: 8px;
    }

    .contact_section,
    .client_section {
        background-image: none;
    }
}

@media (max-width: 480px) {
    .vehicle_img-box {

        width: 100%;

    }

    .admission_container {

        width: 300px;
    }

    .client_container {
        width: 100%;
    }

    .client_detail {

        flex-direction: column;

    }

    .client_detail-box {

        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .client_detail .client_img-box {
        width: 100px;
        margin-right: 0px;
        margin-bottom: 15px;
    }

}

@media (max-width: 400px) {}

@media (max-width: 360px) {}

@media (min-width: 1120px) {
    .container {
        max-width: 1170px;
    }

    .hero_detail-box {
        width: 45%;
    }

    .hero_img-container {
        width: 55%;
    }

}