.masonry_container {
    padding: 3rem;

    .row {
        columns: 2;
        column-gap: 3rem;

        @media (max-width: 992px) {
            columns: 1;
        }

        .item {
            display: block;
            width: 100%;
            margin-bottom: 3rem;

            img {
                width: 100%;
            }
        }
    }
}

/* CARDS */




.intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;

    p {
        margin-bottom: 0;
    }
}

.people {
    padding: 50px 0;
}

.item {
    text-align: center;
    break-inside: avoid-column;


    h4 {
        font-weight: bold;
        margin-bottom: 40px;
        padding-top: 40px;
        color: inherit;
        color: #9a0000;

        @media (max-width:767px) {
            margin-bottom: 25px;
            padding-top: 25px;
            font-size: 24px;
        }
    }

    .box {
        text-align: center;
        padding: 30px;
        background-color: #ffffff5e;
        border: 1px solid #ddddddc2;
        border-radius: 30px;
        margin-bottom: 30px;
    }

    .name {
        font-weight: bold;
        margin-top: 28px;
        margin-bottom: 8px;
        color: inherit;
    }

    .title {
        text-transform: uppercase;
        font-weight: bold;
        color: #d0d0d0;
        letter-spacing: 2px;
        font-size: 13px;
    }

    h4 {
        text-align: left;
    }

    .description {
        font-size: 15px;
        margin-top: 15px;
        margin-bottom: 20px;
        text-align: left;

        ul {
            list-style: none;
            padding-left: 0px;

            li:before {
                content: '✓';
                padding-right: 10px;
            }
        }

    }

    img {
        max-width: 100px;

    }

}

.social {
    font-size: 18px;
    color: #a2a8ae;

    a {
        color: inherit;
        margin: 0 10px;
        display: inline-block;
        opacity: 0.7;

        :hover {
            opacity: 1;
        }
    }
}